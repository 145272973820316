<template>
    <main id="main" class="site-main site-main-detail">
			<div class="postercard">
				<div class="postercard__minipic">
					<img src="uploads/img32.jpg" width="1400" height="448" alt="image description">
				</div>
			</div>
			<section class="blog">
				<div class="container">
					<div class="row">
						<div class="col xs-12 sm-8 md-9">
							<div class="breadcrumb">
								<ul>
									<li><router-link :to="{name:'Home'}">Home</router-link></li>
									<li><span class="live">Our Team</span></li>
								</ul>
							</div>
							<h1 class="folio__title">Our Perfect Team</h1>
							<div class="team__intro">
								<p>Alone impossible! We believe in a team, only teamwork!  We believe in teamwork in good faith for everyone, who works his or her experience and capabilities. Our team is a hard rock; we play our role as our position.  We have been worked in a team, since our establishment. A good team is key to our success and our success is the satisfaction of clients. We believe! This is the main differentiation from others, the reason that Nepal alternative is about for your venture to deal with impossible to possible in the Himalayas. Let’s join us for wonderful experiences in this untouched land of the world.</p>
							</div>
							<div class="team__head">
								<ul class="team__head-list">
									<li data-tab="tab1" class="active"><button type="button" class="tgrTeam">Management</button></li>
									<li data-tab="tab2"><button type="button" class="tgrTeam">Advisor</button></li>
									<li data-tab="tab3"><button type="button" class="tgrTeam">Trekking Guide</button></li>
									<li data-tab="tab4"><button type="button" class="tgrTeam">Female Trekking Guide</button></li>
									<li data-tab="tab5"><button type="button" class="tgrTeam">Climbing Sherpa</button></li>
									<li data-tab="tab6"><button type="button" class="tgrTeam">Yoga Trainer</button></li>
								</ul>
							</div>
							<div class="team__tabset">
								<div id="tab1" class="team__tabitem">
									<div class="member" v-for="(managementteam,index) in managementteams" :key="index">
										<div class="member__avatar">
											<div class="member__avatar-img">
												<img :src="urlPath+'uploads/media/team/'+managementteam.image" alt="{{managementteam.name}}">
											</div>
											<div class="member__avatar-title">
												<h2>{{managementteam.name}}</h2>
												<span class="meta">{{managementteam.designation}}</span>
											</div>
										</div>
										<div class="member__detail">
											<div class="reviser">
												<p v-html="managementteam.description"></p>
											</div>
										</div>
									</div>
									
								</div>
								<div id="tab2" class="team__tabitem" style="display: none;">
									<div class="member" v-for="(advisor,index) in advisors" :key="index">
										<div class="member__avatar">
											<div class="member__avatar-img">
												<img :src="urlPath+'uploads/media/team/'+advisor.image" alt="{{advisor.name}}">
											</div>
											<div class="member__avatar-title">
												<h2>{{advisor.name}}</h2>
												<span class="meta">{{advisor.designation}}</span>
											</div>
										</div>
										<div class="member__detail">
											<div class="reviser">
												<p v-html="advisor.description"></p>
											</div>
										</div>
									</div>
								</div>
								<div id="tab3" class="team__tabitem" style="display: none;">
									<div class="member" v-for="(guideteam,index) in guideteams" :key="index">
										<div class="member__avatar">
											<div class="member__avatar-img">
												<img :src="urlPath+'uploads/media/team/'+guideteam.image" alt="{{guideteam.name}}">
											</div>
											<div class="member__avatar-title">
												<h2>{{guideteam.name}}</h2>
												<span class="meta">{{guideteam.designation}}</span>
											</div>
										</div>
										<div class="member__detail">
											<div class="reviser">
												<p v-html="guideteam.description"></p>
											</div>
										</div>
									</div>
								</div>
								<div id="tab4" class="team__tabitem" style="display: none;">
									<div class="member" v-for="(femaleguide,index) in femaleguides" :key="index">
										<div class="member__avatar">
											<div class="member__avatar-img">
												<img :src="urlPath+'uploads/media/team/'+femaleguide.image" alt="{{femaleguide.name}}">
											</div>
											<div class="member__avatar-title">
												<h2>{{femaleguide.name}}</h2>
												<span class="meta">{{femaleguide.designation}}</span>
											</div>
										</div>
										<div class="member__detail">
											<div class="reviser">
												<p v-html="femaleguide.description"></p>
											</div>
										</div>
									</div>
								</div>
								<div id="tab5" class="team__tabitem" style="display: none;">
									<div class="member" v-for="(climbingsherpa,index) in climbingsherpas" :key="index">
										<div class="member__avatar">
											<div class="member__avatar-img">
												<img :src="urlPath+'uploads/media/team/'+climbingsherpa.image" alt="{{climbingsherpa.name}}">
											</div>
											<div class="member__avatar-title">
												<h2>{{climbingsherpa.name}}</h2>
												<span class="meta">{{climbingsherpa.designation}}</span>
											</div>
										</div>
										<div class="member__detail">
											<div class="reviser">
												<p v-html="climbingsherpa.description"></p>
											</div>
										</div>
									</div>
								</div>
								<div id="tab6" class="team__tabitem" style="display: none;">
									<div class="member" v-for="(yogatrainer,index) in yogatrainers" :key="index">
										<div class="member__avatar">
											<div class="member__avatar-img">
												<img :src="urlPath+'uploads/media/team/'+yogatrainer.image" alt="{{yogatrainer.name}}">
											</div>
											<div class="member__avatar-title">
												<h2>{{yogatrainer.name}}</h2>
												<span class="meta">{{yogatrainer.designation}}</span>
											</div>
										</div>
										<div class="member__detail">
											<div class="reviser">
												<p v-html="yogatrainer.description"></p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col xs-12 sm-4 md-3">
							<div class="related">
								<h2 class="related__title"><strong>Awesome</strong> Package</h2>
								<featured/>
							</div>
						</div>
					</div>
				</div>
			</section>
		</main>
</template>
<script>
import axios from 'axios';
import jQuery from 'jquery';
import {
    defineAsyncComponent
} from "@vue/runtime-core";
const Awesomepackage = defineAsyncComponent(() => import( /* webpackChunkName: "Awesomepackage" */ './Innercomponent/Awesomepackageinner.vue'));

export default {
    name:'Ourteam',
    data(){
        return{
            apiPath: process.env.VUE_APP_API,
            urlPath:process.env.VUE_APP_PATH,
            managementteams:'',
            guideteams:'',
			advisorteams:'',
			femaleguides:'',
			climbingsherpas:'',
			yogatrainers:'',

        }
    },
    async mounted(){
        this.getManagementteam();
        this.getGuideteam();
		this.getFemaleguide();
		this.getAdvisor();
		this.getClimbingsherpa();
		this.getYogatrainer();
        jQuery('.team__head-list li').click(function(){
            var tab_id = jQuery(this).attr('data-tab');

            jQuery('.team__head-list li').removeClass('active');
            jQuery('.team__tabitem').removeClass('active').slideUp(600);

            jQuery(this).addClass('active');
            jQuery("#"+tab_id).addClass('active');
            jQuery("#"+tab_id).slideDown(600);
        });
  
        
    },
    methods:{
        getManagementteam(){
            axios.get(this.apiPath + 'managementteam')
            .then(response=>{
				this.managementteams = response.data;
            })
        },
        getGuideteam(){
            axios.get(this.apiPath + 'guideteam')
            .then(response=>{
                this.guideteams = response.data;
            })
        },
		getFemaleguide(){
            axios.get(this.apiPath + 'femaleguide')
            .then(response=>{
                this.femaleguides = response.data;
            })
        },
		getAdvisor(){
            axios.get(this.apiPath + 'advisor')
            .then(response=>{
                this.advisorteams = response.data;
            })
        },
		getClimbingsherpa(){
            axios.get(this.apiPath + 'climbingsherpa')
            .then(response=>{
                this.climbingsherpas = response.data;
            })
        },
		getYogatrainer(){
            axios.get(this.apiPath + 'yogatrainer')
            .then(response=>{
                this.yogatrainers = response.data;
            })
        }
    },
    components: {
        'featured': Awesomepackage,
     
    }
}
</script>